<!-- 明细表 -->
<template>
    <div class="DetailedTable">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">明细表</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <div class="">
            <!-- <button class="census" type="button" @click="updata()">统计表</button> -->
        </div>
        <el-divider></el-divider>
        <!-- 表格 -->
        <el-table :data="tableData" border stripe style="width:97.9%;flex: 1;" height="650" >
            <!-- 复选按钮 -->
            <el-table-column type="selection" align="center" width="50">
            </el-table-column>
            <el-table-column type="index" label="序号" align="center" width="80">
            </el-table-column>
            <el-table-column prop="student_card" sortable label="学生号" align="center" width="120">
            </el-table-column>
            <el-table-column prop="student_name"
             :sortable="true" :sort-method="fn2" label="学生姓名" align="center" width="120">
            </el-table-column>
            <el-table-column prop="registration" sortable label="报名日期" align="center" width="120">
            </el-table-column>
            <el-table-column prop="peyment_date" sortable label="交费日期" align="center" width="300">
            </el-table-column>
            <el-table-column prop="drop_out" sortable label="退(转)学日期" align="center" width="150">
            </el-table-column>
            <el-table-column prop="train_status" sortable label="学籍类" align="center" width="120">
                <template slot-scope="scope">
                    <span v-if="scope.row.train_status==1">开班入学</span>
                    <span v-else-if="scope.row.train_status==2">插班入学</span>
                    <span v-else-if="scope.row.train_status==3">转班入学</span>
                    <span v-else-if="scope.row.train_status==4">结业1</span>
                    <span v-else-if="scope.row.train_status==5">结业2</span>
                    <span v-else-if="scope.row.train_status==6">结业3</span>
                    <span v-else-if="scope.row.train_status==7">退学</span>
                </template>
            </el-table-column>
            <el-table-column prop="bm_lecture_number" sortable label="报名上课次数" align="center" width="150">
            </el-table-column>
            <el-table-column prop="tuition" sortable label="实付学费" align="center" width="120">
            </el-table-column>
            <el-table-column prop="refund_amount" sortable label="退(转)费" align="center" width="120">
            </el-table-column>
            <el-table-column prop="discount_ratio" sortable label="优惠率(%)" align="center" width="120">
            </el-table-column>
            <el-table-column prop="discount_price" sortable label="让利" align="center" width="120">
            </el-table-column>
            <el-table-column prop="bz_tuition" sortable label="学费标准" align="center" width="120">
            </el-table-column>
            <el-table-column prop="bz_lecture_number" sortable label="授课次数" align="center" width="120">
            </el-table-column>
            <el-table-column prop="transfer_tuition" sortable label="转入金额" align="center" width="120">
            </el-table-column>
            <el-table-column prop="bz_tuition" sortable label="学费" align="center" width="120">
            </el-table-column>
            <el-table-column prop="handle" sortable label="经办人员" align="center" width="120">
            </el-table-column>
            <el-table-column prop="remark" sortable label="说明" align="center" width="300">
            </el-table-column>
            <el-table-column prop="bz_lecture_price" sortable label="标准每课次学费" align="center" width="150">
            </el-table-column>
            <el-table-column prop="bm_lecture_price" sortable label="实际每课次学费" align="center" width="150">
            </el-table-column>
        </el-table>
        <!--分页 -->
        <el-pagination @size-change="handleSizeChange"
         @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[20,50,100,200,500,1000]" :page-size="10" background
            layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
        <!-- 统计表弹窗 -->
        <el-dialog title="统计表" width="1005px" :visible.sync="dialog" :show-close="false"
         :close-on-click-modal='false'>
              <el-form ref="form" :model="form" inline class="form" label-width="120px">
                  <el-form-item label="搜索">
                      <el-input  placeholder="请输入关键词"></el-input>
                  </el-form-item>
                  <el-form-item label="状态">
                      <el-select v-model="form.condition" placeholder="请选择" :clearable="true">
                          <el-option
                            v-for="item in form.stateOpt"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                          </el-option>
                        </el-select>
                  </el-form-item>
                  <el-form-item label="现金记录数">
                      <el-input  placeholder=""></el-input>
                  </el-form-item>
                  <el-form-item label="现金合计">
                      <el-input  placeholder=""></el-input>
                  </el-form-item>
                  <el-form-item label="银行转账记录数">
                      <el-input  placeholder=""></el-input>
                  </el-form-item>
                  <el-form-item label="银行卡合计">
                      <el-input  placeholder=""></el-input>
                  </el-form-item>
                  <el-form-item label="信用卡记录数">
                      <el-input  placeholder=""></el-input>
                  </el-form-item>
                  <el-form-item label="信用卡合计">
                      <el-input  placeholder=""></el-input>
                  </el-form-item>
                  <el-form-item label="微信记录数">
                      <el-input  placeholder=""></el-input>
                  </el-form-item>
                  <el-form-item label="微信合计">
                      <el-input  placeholder=""></el-input>
                  </el-form-item>
                  <el-form-item label="记录数">
                      <el-input  placeholder=""></el-input>
                  </el-form-item>
                  <el-form-item label="金额汇总">
                      <el-input  placeholder=""></el-input>
                  </el-form-item>
              </el-form> 
              <el-divider></el-divider>
              <div  class="footer">
              	<el-button style="background-color: #18BC9C;color: #FFFFFF;" @click="dialog=false"> 
                      确认
                  </el-button>
              	<el-button style="background-color: #f5f5f5;color: #666;" @click="dialog=false">取消</el-button>
              </div>
        </el-dialog>
    </div>
</template>

<script>
    export default{
        data() {
            return{
               tableData:[],
                currentPage: 1,
                currentLimit:20,//条数
                total: 0, 
                dialog:false,
                form:{
                    search:"",//搜索
                    condition:0,//班类型
                    stateOpt:[
                        {label: "全部", value: 0},
                        {label: "开班入学", value: 1},
                        {label: "插班入学", value: 2},
                        {label: "转班入学", value: 3},
                        {label: "结业1", value: 4},
                        {label: "结业2", value: 5},
                        {label: "结业3", value: 6},
                        {label: "退学", value: 7},
                    ],//班类型选项
                }
            }
        },
        created() {
            this.getList()
        },
        methods:{
            goBack() { //返回
                this.$router.go(-1);
            },
            getList(){
                this.$request({
                    url:'/api/studenttrain/list',
                    method:'POST',
                    data:{
                        page:this.currentPage,
                        limit:this.currentLimit
                    }
                }).then(res=>{
                    if(res.code==1){
                        console.log(res)
                        this.tableData = res.data.list
                        this.total = res.data.count
                    }
                })
            },
            handleSizeChange(val) {
                this.currentLimit=val
                this.getList()
                // console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                this.currentPage=val
                this.getList()
                // console.log(`当前页: ${val}`);
            },
            updata(){//打开统计表
                this.dialog=true
            },
            // 姓名排序
            fn2(a, b){
                return a.student_name.localeCompare(b.student_name)
            }
        }
    }
</script>

<style scoped="scoped">
    .DetailedTable{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 20px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    /* 统计表按钮 */
    .census{
        display: inline-block;
        margin-left: 25px;
        width: 93px;
        height: 32px;
        color: #FFFFFF;
        border-radius: 3px;
        border: none;
        padding-left: 20px;
        background:url(../../assets/census.png) 12px 6px no-repeat #29726C ;
        cursor: pointer;
    }
    /* 表格 */
    .el-table {
        margin: 20px;
        margin-top: 0px;
    }
    
    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 30px;
    }
    /* 分页 */
    .el-pagination {
        margin: 20px;
        margin-top: 0px;
    }
    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active{
        background-color: #2C3E50 !important;
    }
    /* 弹出框 */
    .DetailedTable ::v-deep .el-dialog{
        width: 1005px !important;
    }
    .DetailedTable ::v-deep .el-dialog__header{
        text-align: center !important;
    }
    /* 弹窗内表单 */
    .form {
        padding-top: 34px;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin: 0px 30px 0px 10px;
    }
    
    .form ::v-deep .el-input__inner {
        width: 300px;
        height: 37px;
    }
    
    .form ::v-deep .el-form-item__label {
        color: #000000;
    }
    ::v-deep .footer{
        /* margin-top: 10px !important; */
        text-align: center !important;
        height: 70px !important; 
    }
</style>
